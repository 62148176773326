var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-form',[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"text-h5 Anthrazit--text"},[_vm._v(_vm._s(_vm.$t('title')))])]),_c('v-card-text',[_c('d4y-table',{attrs:{"isShowTreeNavToolbar":false,"haveMoreActions":true,"searchLabel":_vm.$t('table_search_label'),"tableHasFilterBtn":false,"loadingFacet":_vm.loadingFacet,"hasFooterBtn":false,"loading":_vm.kollegenauslieferungsIsLoading,"options":_vm.kollegenauslieferungsSearchParams.dataOption,"searchMenuObj":_vm.searchMenuObj,"isFooterBtnDisabled":_vm.isHasSearchData,"serverItemsLength":_vm.getKollegenauslieferung.total,"tableHeaderText":_vm.tableText,"isSearchDataEmpty":_vm.isAllFiltersEmpty,"items":_vm.items,"headers":_vm.headers},on:{"update:options":_vm.selectionUpdateOptions,"input":function($event){return _vm.changeSearchInput($event)},"search:chip":_vm.searchInRealObjects,"remove:chip":_vm.removeChip,"click:clear-all-filter":_vm.clearAllFilters},scopedSlots:_vm._u([{key:"ident_nr",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue--text text-decoration-underline cursor_pointer",on:{"click":function($event){$event.stopPropagation();return _vm.openInvoice(item)}}},[_vm._v(_vm._s(item.ident_nr))])]}},{key:"gs_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.gs_name)+" ")]}},{key:"zip_circle",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.zip_circle.length > 23)?_c('v-tooltip',{attrs:{"openOnlick":false,"openOnFocus":false,"right":"","color":"white","content-class":"tooltip-border"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[(item.zip_circle.length > 23)?_c('div',{staticClass:"black--text my-1"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.$t('zip_circle')))]),_c('v-divider',{staticClass:"my-1"}),_c('div',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(item.zip_circle.replaceAll(',', ', ')))])],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-flex align-center ml-2",class:[item.zip_circle.length > 23 ? 'ml-2' : 'ml-10']},[_vm._v(_vm._s(_vm.getValueZipCircle(item.zip_circle)))])],1)]}},{key:"deliverAbroad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deliver_abroad == true ? 'Ja' : 'Nein')+" ")]}},{key:"deliverIslandn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deliver_island == true ? 'Ja' : 'Nein')+" ")]}},{key:"deliverColleague",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.delivery_colleague == true ? 'Ja' : 'Nein')+" ")]}},{key:"delivery_colleague_productgroup",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.delivery_colleague_productgroup.length > 60)?_c('v-tooltip',{attrs:{"openOnlick":false,"openOnFocus":false,"left":"","color":"white","content-class":"tooltip-border"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('div',{staticClass:"black--text my-1"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.$t('delivery_colleague_productgroup')))]),_c('v-divider',{staticClass:"my-1"}),_c('div',{staticStyle:{"text-align":"left"}},[_c('span',[_vm._v(_vm._s(item.delivery_colleague_productgroup))])])],1)]):_vm._e(),_c('div',{class:[item.delivery_colleague_productgroup.length > 60 ? 'ml-2' : 'ml-10']},[_vm._v(_vm._s(_vm.getValueDeliveryColleagueProductgroup(item.delivery_colleague_productgroup)))])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }