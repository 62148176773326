import { render, staticRenderFns } from "./tblKollegenauslieferung.html?vue&type=template&id=9dac8ad4&scoped=true&"
import script from "./tblKollegenauslieferung.ts?vue&type=script&lang=ts&"
export * from "./tblKollegenauslieferung.ts?vue&type=script&lang=ts&"
import style0 from "./tblKollegenauslieferung.scss?vue&type=style&index=0&id=9dac8ad4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dac8ad4",
  null
  
)

/* custom blocks */
import block0 from "./tblKollegenauslieferung.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fkollegenauslieferung%2FtblKollegenauslieferung.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VCardTitle,VDivider,VForm,VIcon,VTooltip})
