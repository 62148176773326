import { CONST } from '@/shared/utils/Constants';
import { ROUTES } from '@/router/routesEnum';
import { Component, Vue } from 'vue-property-decorator';
import DateField from '@/components/_common/date-field/DateField.vue';
import { namespace } from 'vuex-class';
import { OdataItems } from '@/shared/model/OdataItems';
import { TblUpdateSupplier, CountUpdateRecords } from '@/shared/model/tblUpdateSupplier';
import { TblUpdateCondition } from '@/shared/model/tblUpdateCondition';
import { TblUpdateDocument } from '@/shared/model/tblUpdateDocument';
import { TblUpdateNews } from '@/shared/model/tblUpdateNews';
import { SearchParams } from '@/shared/model/searchParams';
import { CompanyWrGruppenResult, LieferantengruppenResult } from '@/shared/model/company';
import GeneralUtils from '@/shared/utils/generalUtils';

const tblUpdateSupplierModule = namespace('tblUpdateSupplier');
const tblUpdateConditionModule = namespace('tblUpdateCondition');
const tblUpdateDocumentModule = namespace('tblUpdateDocument');
const tblUpdateNewsModule = namespace('tblUpdateNews');
const authModule = namespace('auth');

@Component({
  components: { DateField },
})
export default class TasksDashboard extends Vue {
  @tblUpdateSupplierModule.Action('getCountUpdateRecors')
  private actionGetCountUpdateRecors!: any;
  @tblUpdateSupplierModule.Action('getTblUpdateSuppliers')
  private actionGettblUpdateSuppliers!: any;
  @tblUpdateSupplierModule.Action('updateDashboardDate')
  private actionUpdateDashboardDate!: any;
  @tblUpdateSupplierModule.Getter('getDashboardSupplieDate')
  private dashboardSupplieDate!: any;
  @tblUpdateSupplierModule.Action('getCompanyWrGruppen')
  private actionGetCompanyWrGruppen!: any;
  @tblUpdateSupplierModule.Action('getLieferantengruppen')
  private actionGetLieferantengruppen!: any;

  @tblUpdateConditionModule.Action('updateDashboardDate')
  private actionUpdateConditionDashboardDate!: any;
  @tblUpdateDocumentModule.Action('updateDashboardDate')
  private actionUpdateDocumentDashboardDate!: any;
  @tblUpdateDocumentModule.Getter('getDashboardDate')
  private dashboardDocumentDate!: any;
  @tblUpdateSupplierModule.Getter('getTblUpdateSuppliers')
  private tblUpdateSuppliers!: OdataItems<TblUpdateSupplier>;
  @tblUpdateSupplierModule.Getter('getTblUpdateSuppliersSearchParams')
  private tblUpdateSuppliersSearchParams!: SearchParams;
  @tblUpdateConditionModule.Action('updateDashboarCompanyWrGruppen')
  private actionUpdateDashboarCompanyWrGruppen!: any;
  @tblUpdateSupplierModule.Action('updateDashboarCompanyWrGruppen')
  private actionUpdateDashboarCompanyWrGruppenSp!: any;
  @tblUpdateSupplierModule.Action('updateDashboardLieferantengruppen')
  private actionUpdateDashboardLieferantengruppen!: any;

  @tblUpdateConditionModule.Action('getTblUpdateConditions')
  private actionGettblUpdateConditions!: any;
  @tblUpdateConditionModule.Getter('getTblUpdateConditions')
  private tblUpdateConditions!: OdataItems<TblUpdateCondition>;
  @tblUpdateConditionModule.Getter('getTblUpdateConditionsSearchParams')
  private tblUpdateConditionsSearchParams!: SearchParams;

  @tblUpdateDocumentModule.Action('getTblUpdateDocuments')
  private actionGettblUpdateDocuments!: any;
  @tblUpdateDocumentModule.Getter('getTblUpdateDocuments')
  private tblUpdateDocuments!: OdataItems<TblUpdateDocument>;
  @tblUpdateDocumentModule.Getter('getTblUpdateDocumentsSearchParams')
  private tblUpdateDocumentsSearchParams!: SearchParams;

  @tblUpdateNewsModule.Action('getTblUpdateNews')
  private actionGettblUpdateNews!: any;
  @tblUpdateNewsModule.Getter('getTblUpdateNews')
  private tblUpdateNews!: OdataItems<TblUpdateNews>;
  @tblUpdateNewsModule.Getter('getTblUpdateNewsSearchParams')
  private tblUpdateNewsSearchParams!: SearchParams;

  @tblUpdateDocumentModule.Action('getCountWerbungDocument')
  private actionGetCountWerbungDocument!: any;
  @tblUpdateDocumentModule.Getter('getTblUpdateDocumentrsWerbungTotal')
  private getWerbungDocumentCount!: any;

  @tblUpdateConditionModule.Action('updateDashboardConditionsStatus')
  private actionGetDashboardConditionsStatus!: any;
  @tblUpdateConditionModule.Getter('getDashboadrdConditionsStatus')
  private dashboardConditionsStatus!: any;
  @tblUpdateDocumentModule.Action('updateDashboardDocumentsStatus')
  private actionGetDashboardDocumentsStatus!: any;
  @tblUpdateDocumentModule.Getter('getDashboadrdDocumentsStatus')
  private dashboardDocumentsStatus!: any;
  @tblUpdateSupplierModule.Action('updateDashboardSuppliersStatus')
  private actionGetDashboardSuppliersStatus!: any;
  @tblUpdateSupplierModule.Getter('getDashboadrSupplierStatus')
  private dashboardSuppliersStatus!: any;

  @tblUpdateConditionModule.Action('getConditionsVisible')
  private actionGetConditionsVisible!: any;
  @tblUpdateConditionModule.Getter('getConditionsVisible')
  private getConditionsVisible!: boolean;

  @tblUpdateNewsModule.Action('updateDashboardDate')
  private actionUpdateNewsDashboardDate!: any;

  @authModule.Action('getEnvironment')
  private getEnvironment: any;

  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Getter('azureUserEmail')
  private azureEmail!: any;

  // get azureEmail() {
  //   console.log('azureUserEmail :>> ', this.azureUserEmail);
  //   let emailFromUrl = GeneralUtils.getObjProperty(this.$route.query, CONST.emailUrlParam);
  //   console.log('azureEmailazureEmailemail :>> ', email);
  //   return this.azureUser?.idTokenClaims?.preferred_username || emailFromUrl;
  // }

  @tblUpdateConditionModule.Getter('getDashboardDate')
  private dashboardDate!: any;
  @tblUpdateConditionModule.Getter('getDashboarCompanyWrGruppen')
  private dashboardCompanyWrGruppen!: any;

  companyWrGruppen: CompanyWrGruppenResult[] = [];
  lieferantengruppen: LieferantengruppenResult[] = [];
  private companiesWrGruppen: string[] = [];
  private lieferantenGruppen: string[] = [];
  listCountUpdateRecords: CountUpdateRecords[] = [];
  private selectedStatus: string = '';

  dateSearch: any = '';
  isNotProductionEnv = false;

  changeDate(value: any) {
    // prevent strange 2 request with the same value
    if (this.dateSearch == value) {
      return;
    }

    this.dateSearch = value || undefined; // new Date(value).toJSON();
    // this.triggerSearchDataUpdate();
    // this.actionGettblUpdateSuppliers({
    //   searchParams: this.tblUpdateSuppliersSearchParams,
    //   supplierSearchData: {
    //     datum: this.dateSearch,
    //     companiesWrGruppen: this.companiesWrGruppen,
    //     lieferantenGruppen: this.lieferantenGruppen,
    //     contactEmail: this.azureEmail,
    //     statusSupplier: this.selectedStatus,
    //   },
    // });

    // this.actionGettblUpdateConditions({
    //   searchParams: this.tblUpdateConditionsSearchParams,
    //   conditionSearchData: {
    //     datum: this.dateSearch,
    //     companiesWrGruppen: this.companiesWrGruppen,
    //     lieferantenGruppen: this.lieferantenGruppen,
    //     contactEmail: this.azureEmail,
    //     statusCondition: this.selectedStatus,
    //   },
    // });
    // console.log('***********************changeDate****get TblUpdateDocuments***************  ===>>>   ');
    // this.actionGettblUpdateDocuments({
    //   searchParams: this.tblUpdateDocumentsSearchParams,
    //   documentSearchData: {
    //     datum: this.dateSearch,
    //     contactEmail: this.azureEmail,
    //     statusDocument: this.selectedStatus,
    //   },
    // });

    // this.actionGetCountWerbungDocument({
    //   searchParams: this.tblUpdateDocumentsSearchParams,
    //   documentSearchData: {
    //     datum: this.dateSearch,
    //     documentType: 'werbung',
    //     contactEmail: this.azureEmail,
    //     statusDocument: this.selectedStatus,
    //   },
    // });

    // this.actionGettblUpdateNews({
    //   searchParams: this.tblUpdateNewsSearchParams,
    //   newsSearchData: {
    //     datum: this.dateSearch,
    //     contactEmail: this.azureEmail,
    //     statusNews: this.selectedStatus,
    //   },
    // });
    this.getCountUpdateRecors();
    this.actionUpdateDashboardDate(value);
    this.actionUpdateConditionDashboardDate(value);
    this.actionUpdateDocumentDashboardDate(value);
    this.actionUpdateNewsDashboardDate(value);
  }

  get suppliersCount() {
    return this.tblUpdateSuppliers.total;
  }
  get conditionsCount() {
    return this.tblUpdateConditions.total;
  }

  get documentsCount() {
    return this.tblUpdateDocuments.total;
  }

  get documentsWerbungCount() {
    return this.getWerbungDocumentCount;
  }

  get newsCount() {
    return this.tblUpdateNews.total;
  }

  created() {
    if (this.dashboardDate) {
      this.dateSearch = this.dashboardDate;
    } else if (this.dashboardSupplieDate) {
      this.dateSearch = this.dashboardSupplieDate;
    } else if (this.dashboardDocumentDate) {
      this.dateSearch = this.dashboardDocumentDate;
    } else {
      this.dateSearch = this.getPeriodForDashBoard();
    }
    this.selectedStatus = 'open';
    this.getEnvFromBackend();
    this.getCountUpdateRecors();
  }

  async mounted() {
    this.selectedStatus = 'open';
    if (this.dashboardDate) {
      this.dateSearch = this.dashboardDate;
      this.actionUpdateDashboardDate(this.dateSearch);
      this.actionUpdateDocumentDashboardDate(this.dateSearch);
      this.actionUpdateNewsDashboardDate(this.dateSearch);
    } else if (this.dashboardSupplieDate) {
      this.dateSearch = this.dashboardSupplieDate;
      this.actionUpdateConditionDashboardDate(this.dateSearch);
      this.actionUpdateDashboardDate(this.dateSearch);
      this.actionUpdateNewsDashboardDate(this.dateSearch);
    } else if (this.dashboardDocumentDate) {
      this.dateSearch = this.dashboardDocumentDate;
      this.actionUpdateConditionDashboardDate(this.dateSearch);
      this.actionUpdateDashboardDate(this.dateSearch);
    } else {
      this.dateSearch = this.getPeriodForDashBoard();
      this.actionUpdateDashboardDate(this.dateSearch);
      this.actionUpdateConditionDashboardDate(this.dateSearch);
      this.actionUpdateDocumentDashboardDate(this.dateSearch);
      this.actionUpdateNewsDashboardDate(this.dateSearch);
    }

    this.actionGetDashboardSuppliersStatus('open');
    this.actionGetDashboardConditionsStatus('open');
    this.actionGetDashboardDocumentsStatus('open');
    this.actionGetConditionsVisible(this.azureEmail);
    var promiseAll = [
      //this.getTblUpdateSuppliers(),
      //this.getCountUpdateRecors(),
      //this.getEnvFromBackend(),
      //this.getTblUpdateConditions(),
      //this.getTblUpdateDocuments(),
      //this.getTblUpdateWerbungDocuments(),
      this.getCompanyZrsFromBackend(),
      this.getLieferantengruppen(),
      //this.getTblUpdateNews(),
    ];

    await Promise.all(promiseAll);

    this.actionUpdateDashboarCompanyWrGruppen();
    this.actionUpdateDashboarCompanyWrGruppenSp();
    this.actionUpdateDashboardLieferantengruppen();
  }

  private async getTblUpdateSuppliers() {
    await this.actionGettblUpdateSuppliers({
      searchParams: this.tblUpdateSuppliersSearchParams,
      supplierSearchData: {
        datum: this.dateSearch,
        companiesWrGruppen: this.companiesWrGruppen,
        lieferantenGruppen: this.lieferantenGruppen,
        contactEmail: this.azureEmail,
        statusSupplier: this.selectedStatus,
      },
    });
  }

  private async getTblUpdateConditions() {
    await this.actionGettblUpdateConditions({
      searchParams: this.tblUpdateConditionsSearchParams,
      conditionSearchData: {
        datum: this.dateSearch,
        companiesWrGruppen: this.companiesWrGruppen,
        lieferantenGruppen: this.lieferantenGruppen,
        contactEmail: this.azureEmail,
        statusCondition: this.selectedStatus,
      },
    });
  }

  private async getTblUpdateDocuments() {
    await this.actionGettblUpdateDocuments({
      searchParams: this.tblUpdateDocumentsSearchParams,
      documentSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        statusDocument: this.selectedStatus,
      },
    });
  }

  private async getTblUpdateNews() {
    await this.actionGettblUpdateNews({
      searchParams: this.tblUpdateNewsSearchParams,
      newsSearchData: {
        datum: this.dateSearch,
        contactEmail: this.azureEmail,
        statusNews: this.selectedStatus,
      },
    });
  }

  private async getTblUpdateWerbungDocuments() {
    await this.actionGetCountWerbungDocument({
      searchParams: this.tblUpdateDocumentsSearchParams,
      documentSearchData: {
        datum: this.dateSearch,
        documentType: 'werbung',
        contactEmail: this.azureEmail,
        statusDocument: this.selectedStatus,
      },
    });
  }

  private async getCompanyZrsFromBackend() {
    await this.actionGetCompanyWrGruppen({ searchData: { contactEmail: this.azureEmail } })
      .then((result: CompanyWrGruppenResult[]) => {
        this.companyWrGruppen = result;
      })
      .catch((err: any) => {});
  }

  private async getLieferantengruppen() {
    await this.actionGetLieferantengruppen({ searchData: { contactEmail: this.azureEmail } })
      .then((result: LieferantengruppenResult[]) => {
        this.lieferantengruppen = result;
      })
      .catch((err: any) => {});
  }

  private async getCountUpdateRecors() {
    await this.actionGetCountUpdateRecors({
      searchParams: this.tblUpdateSuppliersSearchParams,
      supplierSearchData: {
        datum: this.dateSearch,
        companiesWrGruppen: this.companiesWrGruppen,
        lieferantenGruppen: this.lieferantenGruppen,
        contactEmail: this.azureEmail,
        statusSupplier: this.selectedStatus,
      },
    })
      .then((result: CountUpdateRecords[]) => {
        this.listCountUpdateRecords = result;
      })
      .catch((err: any) => {});
  }

  // TODO: (EGRUP-84) Move this method in global space (probably try to mixin/helper or something like that )
  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  private openSuppliers() {
    this.$router.push({
      name: ROUTES.tasks_suppliers,
    });
  }

  private openConditios() {
    this.$router.push({
      name: ROUTES.tasks_conditions,
    });
  }

  private openDocuments() {
    this.$router.push({
      name: ROUTES.tasks_documents,
    });
  }

  private openWerbung() {
    this.$router.push({
      name: ROUTES.werbung,
    });
  }

  private openNews() {
    this.$router.push({
      name: ROUTES.news,
    });
  }

  changeCompanyWrGruppen() {
    this.actionUpdateDashboarCompanyWrGruppen(this.companiesWrGruppen);
    this.actionUpdateDashboarCompanyWrGruppenSp(this.companiesWrGruppen);
    this.getCountUpdateRecors();
    //this.getTblUpdateSuppliers();
    //this.getTblUpdateConditions();
  }

  changeLieferantengruppen() {
    this.actionUpdateDashboardLieferantengruppen(this.lieferantenGruppen);
    this.getCountUpdateRecors();
    //this.getTblUpdateSuppliers();
    //this.getTblUpdateConditions();
  }

  getPeriodForDashBoard(): string {
    let dateStart = new Date();
    let dateStartDay = dateStart.getDay();
    let dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate() + (7 - dateStartDay));
    let startDate = new Date();
    startDate.setDate(dateEnd.getDate() - 13);
    const periodForDashBoard =
      startDate.getFullYear() +
      '-' +
      ('0' + (startDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + startDate.getDate()).slice(-2) +
      ',' +
      dateEnd.getFullYear() +
      '-' +
      ('0' + (dateEnd.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + dateEnd.getDate()).slice(-2);
    return periodForDashBoard;
  }

  changeSelectStatus() {
    this.actionGetDashboardConditionsStatus(this.selectedStatus);
    this.actionGetDashboardDocumentsStatus(this.selectedStatus);
    this.actionGetDashboardSuppliersStatus(this.selectedStatus);
    this.getCountUpdateRecors();
  }

  getEnvFromBackend() {
    this.getEnvironment().then((result: any) => {
      if (result.toUpperCase() != 'PRODUCTION') {
        this.isNotProductionEnv = true;
      }
    });
  }

  activeState = [
    {
      text: this.$i18n.t(`select_all`), //'Alle',
      value: 'all',
    },
    {
      text: this.$i18n.t(`select_offen`), //'Offen',
      value: 'open',
    },
    {
      text: this.$i18n.t(`select_erledigt`), //'Erledigt',
      value: 'done',
    },
  ];

  goToNews() {}
  goToKatalog() {}
}
