import { ROUTES } from '@/router/routesEnum';
import tblUpdateNews, { TblUpdateNews, BereichListResult, CategoryListResult, AuthorListResult } from '@/shared/model/tblUpdateNews';
import { CompletedInfo } from '@/shared/model/tblUpdateSupplier';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST } from '@/shared/utils/Constants';
import Vue from 'vue';
import DateUtils from '@/shared/utils/DateUtils';
import { Contact } from '@/shared/model/contact';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import GeneralUtils from '@/shared/utils/generalUtils';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import DateField from '@/components/_common/date-field/DateField.vue';
import searchData from '@/shared/model/smallPayloadModels/searchData';
import NewsTooltip from '../../tasks/tblUpdateNews/news-tooltip/news-tooltip.vue';

const name = 'tblUpdateDocuments-view';
const contactModule = namespace('contact');
const tblUpdateNewsModule = namespace('tblUpdateNews');
const tblUpdateDocumentModule = namespace('tblUpdateDocument');
const tblUpdateSupplierModule = namespace('tblUpdateSupplier');
const tblUpdateConditionModule = namespace('tblUpdateCondition');
const authModule = namespace('auth');

@Component({ name: name, components: { D4yTable, DateField, NewsTooltip } })
export default class TblUpdateDocumentsComponent extends Vue {
  @tblUpdateNewsModule.Action('getTblUpdateNews')
  private actionGettblUpdateNews!: any;
  @tblUpdateNewsModule.Getter('getTblUpdateNews')
  private tblUpdateNews!: OdataItems<TblUpdateNews>;
  @tblUpdateNewsModule.Getter('getTblUpdateNewsSearchParams')
  private tblUpdateNewsSearchParams!: SearchParams;
  @tblUpdateNewsModule.Getter('getTblUpdateNewsIsLoading')
  private tblUpdateNewsIsLoading!: boolean;
  @tblUpdateNewsModule.Getter('getTblUpdateNewsTotal')
  private tblUpdateNewsTotal!: number;
  @tblUpdateDocumentModule.Action('deleteTblUpdateDocument')
  private actionDeleteTblUpdateDocument!: any;

  @tblUpdateConditionModule.Action('updateDashboardDate')
  private actionUpdateConditionDashboardDate!: any;
  @tblUpdateSupplierModule.Action('updateDashboardDate')
  private actionUpdateDashboardDate!: any;
  @tblUpdateDocumentModule.Action('updateDashboardDate')
  private actionUpdateDocumentDashboardDate!: any;
  @tblUpdateNewsModule.Action('updateDashboardDate')
  private actionUpdateNewsDashboardDate!: any;
  @tblUpdateNewsModule.Action('getBereichList')
  private actionGetBereichList!: any;
  @tblUpdateNewsModule.Action('getCategoryList')
  private actionGetCategoryList!: any;
  @tblUpdateNewsModule.Action('getAuthorList')
  private actionGetAuthorList!: any;

  @tblUpdateDocumentModule.Getter('getDashboardDate')
  private dashboardDate!: any;
  @contactModule.Getter('getContactForLoggedInUser')
  private contactForLoggedInUser!: Contact;
  @tblUpdateNewsModule.Action('getAddNewsCompleted')
  private actionAddNewsCompleted!: any;
  @tblUpdateNewsModule.Action('getCanselNewsCompleted')
  private actionCanselNewsCompleted!: any;
  @tblUpdateNewsModule.Action('getCountTopNews')
  private actionCountTopNews!: any;

  @tblUpdateDocumentModule.Getter('getDashboadrdDocumentsStatus')
  private dashboardDocumentsStatus!: any;

  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Getter('azureUserEmail')
  private azureEmail!: any;
  private selectedBereich: string[] = [];
  private selectedCategory: string[] = [];
  private selectedAuthor: string[] = [];
  private selectedStatus: string = '';
  private countTopNews: number = 0;

  dateSearch: any = '';
  documentType: any = '';
  private searchTerm = '';
  listBereich: BereichListResult[] = [];
  listCategory: CategoryListResult[] = [];
  listAuthor: AuthorListResult[] = [];
  @Watch('tblUpdateNewsSearchParams.filter', { deep: true })
  public async onOptionsFilterChanged(newValFilter: string, oldValFilter: string) {
    console.log(
      '------------onOptions FilterChanged------------------------------------------------  ===>>>newVal   ',
      newValFilter,
      '  oldVal  ',
      oldValFilter,
      '    this.tblUpdateDocumentsSearchParams   ',
      this.tblUpdateNewsSearchParams
    );
    if (newValFilter == oldValFilter) {
      return;
    }
    this.tblUpdateNewsSearchParams.dataOption.page = 1;
    console.log(
      '****************************************************************------CALLED--------action get TblUpdateNews----FROM---onOptions FilterChanged---------------------------------------  ===>>>   '
    );
    this.actionGettblUpdateNews({
      searchParams: this.tblUpdateNewsSearchParams,
      newsSearchData: {
        datum: this.dateSearch,
        selectedBereich: this.selectedBereich,
        selectedCategory: this.selectedCategory,
        selectedAuthor: this.selectedAuthor,
        contactEmail: this.azureEmail,
        statusNews: this.selectedStatus,
      },
    });
  }

  changeDate(value: any) {
    // prevent strange 2 request with the same value
    if (this.dateSearch == value) {
      return;
    }
    this.dateSearch = value || undefined; // new Date(value).toJSON();
    // this.triggerSearchDataUpdate();
    console.log(
      '****************************************************************------CALLED--------action get TblUpdateNews----FROM--- change Date---------------------------------------  ===>>>   '
    );
    this.actionGettblUpdateNews({
      searchParams: this.tblUpdateNewsSearchParams,
      newsSearchData: {
        datum: this.dateSearch,
        selectedBereich: this.selectedBereich,
        selectedCategory: this.selectedCategory,
        selectedAuthor: this.selectedAuthor,
        contactEmail: this.azureEmail,
        statusNews: this.selectedStatus,
      },
    });
    this.actionUpdateConditionDashboardDate(value);
    this.actionUpdateDashboardDate(value);
    this.actionUpdateDocumentDashboardDate(value);
  }
  
  changeSelectStatus() {
    this.getTblUpdateNews();
  }

  created() {
    if (this.dashboardDate) {
      this.dateSearch = this.dashboardDate;
    }
    if (this.dashboardDocumentsStatus) {
      this.selectedStatus = this.dashboardDocumentsStatus;
    } else {
      this.selectedStatus = 'all';
    }
  }

  isInitialRequest = true;
  async mounted() {
    var promiseAll = [this.getTblUpdateNews(), this.getlistBereichFromBackend(), this.getlistCategoryFromBackend(), this.getlistAuthorFromBackend(), this.getCountTopNews() ];
    await Promise.all(promiseAll);
    this.isInitialRequest = false;
  }
  private async getTblUpdateNews() {
    await this.actionGettblUpdateNews({
      searchParams: this.tblUpdateNewsSearchParams,
      newsSearchData: {
        datum: this.dateSearch,
        selectedBereich: this.selectedBereich,
        selectedCategory: this.selectedCategory,
        selectedAuthor: this.selectedAuthor,
        contactEmail: this.azureEmail,
        statusNews: this.selectedStatus,
      },
    });
  }

  private async getlistBereichFromBackend() {
    await this.actionGetBereichList({ searchData: { contactEmail: this.azureEmail } })
      .then((result: BereichListResult[]) => {
        this.listBereich = result;
      })
      .catch((err: any) => {});
  }

  private async getlistCategoryFromBackend() {
    await this.actionGetCategoryList({ searchData: { contactEmail: this.azureEmail } })
      .then((result: CategoryListResult[]) => {
        this.listCategory = result;
      })
      .catch((err: any) => {});
  }

  private async getlistAuthorFromBackend() {
    await this.actionGetAuthorList({ searchData: { contactEmail: this.azureEmail } })
      .then((result: any) => {
        this.listAuthor = result;
      })
      .catch((err: any) => {});
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      //{ text: this.$t('number'), value: 'doc_zrnummer' }, // ds, 09.09.2024 -> according to the item AD-164
      //{ text: this.$t('document_name'), value: 'doc_name' }, // ds, 09.09.2024 -> according to the item AD-164
      { text: this.$t('news_bild'), value: 'nw_image' },
      { text: this.$t('news_titel'), value: 'nw_title' },
      { text: this.$t('news_text'), value: 'nw_description' },      
      { text: this.$t('news_bereich'), value: 'nw_sitetitle' },
      { text: this.$t('news_kategorie'), value: 'nw_category' },
      { text: this.$t('news_autor'), value: 'nw_author' }, // ds, 09.09.2024 -> according to the item AD-164
      { text: this.$t('news_erstellt_am'), value: 'nw_createdate' }, // ds, 09.09.2024 -> according to the item AD-164
      { text: this.$t('news_anzahlViews'), value: 'nw_countviews', width: '55px' },
      { text: this.$t('news_list_completed'), value: 'newsDoneTimeCompleted' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  get image() {
    return {};
    return {
      src: 'img_tblUpdateSupplier.png',
      header: this.$t('title'),
      width: 360,
      height: 200,
    };
  }

  onAdd() {
    this.add();
  }

  add() {
    // this.$router.push({
    //   name: ROUTES.tblUpdateSupplier,
    //   params: {
    //     tblUpdateSupplierId: CONST.emptyGuid,
    //   },
    // });
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get getFilterFields() {
    return this.tblUpdateNewsSearchParams.orClauseFieldsIds
      .map((x) => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  private async tblUpdateNewsUpdateOptions(newVal: any, oldVal: any) {
    this.tblUpdateNewsSearchParams.dataOption = newVal;
    if (newVal != oldVal && this.tblUpdateNewsIsLoading != undefined && !this.isInitialRequest) this.getTblUpdateNews();
  }

  private async completedNews(tblUpdateNews: any) {
    if (tblUpdateNews.newsDoneTimeCompleted.length == 0) {
      this.actionAddNewsCompleted({
        id: tblUpdateNews.id,
        zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
        contactEmail: this.azureEmail,
      }).then((completedInfo: CompletedInfo) => {
        tblUpdateNews.newsDoneTimeCompleted = DateUtils.formatDateTimeWithLocaleAndTimezone(
          completedInfo.сompletedDoneTime
        );
        tblUpdateNews.newsUserNameCompleted = completedInfo.сompletedUserName;
      });
    } else {
      this.$confirm
        .open(`${this.$t('completed_dialog_title')}`, `${this.$t('completed_dialog_title_text')}`, {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        })
        .then(async (response: any) => {
          if (response) {
            this.actionCanselNewsCompleted({
              id: tblUpdateNews.id,
              zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
              contactEmail: this.azureEmail,
            }).then((completedInfo: CompletedInfo) => {
              tblUpdateNews.newsDoneTimeCompleted = '';
              tblUpdateNews.newsUserNameCompleted = '';
            });
          }
        });
    }
  }

  changeSelectAuthor() {
    this.getTblUpdateNews();
  }

  changeSelectBereich() {
    this.getTblUpdateNews();
  }

  changeSelectCategory() {
    this.getTblUpdateNews();
  }

  get labelTitel() {
    if (this.$route.fullPath.includes('/suppliers')) {
      return this.$t('supplier_status');
    } else {
      return this.$t('employee_status');
    }
  }

  closeNews() {
    this.$router.push({
      name: ROUTES.tasks,
    });
  }

  private changeSearch(searchTerm: string) {
    if (searchTerm.length < 2) return;
    this.searchNews(searchTerm);
  }
  private changeSearchInput(value: string) {
    if (value == null || value.length == 0) {
      this.resetSearch();
    }
  }
  private resetSearch() {
    const emptyString = '';
    this.searchNews(emptyString);
  }

  private async searchNews(filterValue: string) {
    this.tblUpdateNewsSearchParams.filter = filterValue; // `this.get ContactsSearch` will be called via `@Watch`
    // if (!this.contactsIsLoading) this.get ContactsSearch();
  }

  getDescription(value: string) {
    let description: string = '';
    if (value.length > 100) {
      description = value.substring(0, 100);
    } else {
      description = value;
    }
    return description;
  }

  openUrl(item: TblUpdateNews){
    window.open(item.nw_pfad, '_blank');
  }

  getCountTopNews() {
    this.actionCountTopNews()
      .then((result: number) => {
        this.countTopNews = result;
      })
      .catch((err: any) => {
      });
  }

  openTopNews(){
    if (this.selectedCategory.length != 0) {
      this.selectedCategory = [];
    }
    if (this.selectedBereich.length != 0) {
      this.selectedBereich = [];
    }
    if (this.selectedAuthor.length != 0) {
      this.selectedAuthor = [];
    }
    if (this.selectedStatus != null) {
      this.selectedStatus = 'all';
    }
    if (this.dateSearch.length != 0) {
      this.dateSearch = '';
    }
    if (this.tblUpdateNewsSearchParams.filter.length != 0) {
      this.tblUpdateNewsSearchParams.filter = '';
    }   
    this.selectedCategory.push('TOP-News');
    
    this.getTblUpdateNews();
  }

  deleteTblUpdateNews() {}

  activeState = [
    {
      text: this.$i18n.t(`select_all`), //'Alle',
      value: 'all',
    },
    {
      text: this.$i18n.t(`select_offen`), //'Offen',
      value: 'open',
    },
    {
      text: this.$i18n.t(`select_erledigt`), //'Erledigt',
      value: 'done',
    },
  ];

}
