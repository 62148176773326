import { ROUTES } from '@/router/routesEnum';
import tblUpdateSupplier, { TblUpdateSupplier, CompletedInfo } from '@/shared/model/tblUpdateSupplier';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST } from '@/shared/utils/Constants';
import Vue from 'vue';
import { Contact } from '@/shared/model/contact';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import GeneralUtils from '@/shared/utils/generalUtils';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import DateField from '@/components/_common/date-field/DateField.vue';
import { TblUpdateCondition } from '@/shared/model/tblUpdateCondition';
import searchData from '@/shared/model/smallPayloadModels/searchData';
import { CompanyWrGruppenResult, LieferantengruppenResult } from '@/shared/model/company';

const authModule = namespace('auth');
const contactModule = namespace('contact');
const tblUpdateConditionModule = namespace('tblUpdateCondition');
const name = 'tblUpdateSuppliers-view';
const tblUpdateSupplierModule = namespace('tblUpdateSupplier');


@Component({ name: name, components: { D4yTable, DateField } })
export default class TblUpdateSuppliersComponent extends Vue {
  @tblUpdateSupplierModule.Action('getTblUpdateSuppliers')
  private actionGettblUpdateSuppliers!: any;
  @tblUpdateSupplierModule.Getter('getTblUpdateSuppliers')
  private tblUpdateSuppliers!: OdataItems<TblUpdateSupplier>;
  @tblUpdateSupplierModule.Getter('getTblUpdateSuppliersSearchParams')
  private tblUpdateSuppliersSearchParams!: SearchParams;
  @tblUpdateSupplierModule.Getter('getTblUpdateSuppliersTotal')
  private tblUpdateSuppliersTotal!: number;
  @tblUpdateSupplierModule.Getter('getTblUpdateSuppliersIsLoading')
  private tblUpdateSuppliersIsLoading!: boolean;
  @tblUpdateSupplierModule.Action('deleteTblUpdateSupplier')
  private actionDeleteTblUpdateSupplier!: any;
  @tblUpdateSupplierModule.Action('getAddSupplierCompleted')
  private actionAddSupplierCompleted!: any;
  @tblUpdateSupplierModule.Action('getCompanyWrGruppen')
  private actionGetCompanyWrGruppen!: any;
  @tblUpdateConditionModule.Action('updateDashboardDate')
  private actionUpdateConditionDashboardDate!: any;
  @tblUpdateSupplierModule.Action('updateDashboardDate')
  private actionUpdateDashboardDate!: any;
  @tblUpdateSupplierModule.Getter('getDashboardSupplieDate')
  private dashboardDate!: any;
  @tblUpdateSupplierModule.State('lieferantengruppen')
  private lieferantengruppenList!: any;
  @contactModule.Getter('getContactForLoggedInUser')
  private contactForLoggedInUser!: Contact;
  @tblUpdateSupplierModule.Getter('getDashboarCompanyWrGruppen')
  private dashboardCompanyWrGruppen!: any;
  @tblUpdateSupplierModule.Getter('getDashboardLieferantengruppen')
  private dashboardLieferantengruppen!: any;
  @tblUpdateSupplierModule.Action('updateDashboardSuppliersStatus')
  private actionGetDashboardSuppliersStatus!: any;
  @tblUpdateSupplierModule.Getter('getDashboadrSupplierStatus')
  private dashboardSuppliersStatus!: any;
  @tblUpdateSupplierModule.Action('getCanselSupplierCompleted')
  private actionCanselSupplierCompleted!: any;

  @authModule.Getter('getUser')
  private user!: any;
  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Getter('azureUserEmail')
  private azureEmail!: any;

  companyWrGruppen: CompanyWrGruppenResult[] = [];
  lieferantengruppen: LieferantengruppenResult[]= [];
  private companiesWrGruppen: string[] = [];
  private lieferantenGruppen: string[] = [];
  private selectedStatus: string = '';
  dateSearch: any = '';
  changeDate(value: any) {
    // prevent strange 2 request with the same value
    if (this.dateSearch == value) {
      return;
    }

    this.dateSearch = value || undefined; // new Date(value).toJSON();
    // this.triggerSearchDataUpdate();
    this.actionGettblUpdateSuppliers({
      searchParams: this.tblUpdateSuppliersSearchParams,
      supplierSearchData: {
        datum: this.dateSearch,
        companiesWrGruppen: this.companiesWrGruppen,
        lieferantenGruppen: this.lieferantenGruppen,
        contactEmail: this.azureEmail,
        statusSupplier: this.selectedStatus,
      },
    });

    this.actionUpdateDashboardDate(value);
    this.actionUpdateConditionDashboardDate();
  }

  created() {
    this.lieferantengruppen=this.lieferantengruppenList;
    if (this.dashboardDate) {
      this.dateSearch = this.dashboardDate;
    }
    if (this.dashboardCompanyWrGruppen) {
      this.companiesWrGruppen = this.dashboardCompanyWrGruppen;
    }
    if (this.dashboardSuppliersStatus) {
      this.selectedStatus = this.dashboardSuppliersStatus;
    } else {
      this.selectedStatus = 'all';
    }
    if (this.dashboardLieferantengruppen) {
      this.lieferantenGruppen = this.dashboardLieferantengruppen;
    }
  }

  get zrMaster() {
    return this.contactForLoggedInUser?.zrMaster;
  }

  get listZrNummer() {
    return this.contactForLoggedInUser?.listZrNummer;
  }

  isInitialRequest =true;
  async mounted() {
    var promiseAll = [this.getTblUpdateSuppliers(), this.getCompanyZrsFromBackend()];
    await Promise.all(promiseAll);
    this.isInitialRequest = false;
  }
  private async getTblUpdateSuppliers() {
    await this.actionGettblUpdateSuppliers({
      searchParams: this.tblUpdateSuppliersSearchParams,
      supplierSearchData: {
        datum: this.dateSearch,
        companiesWrGruppen: this.companiesWrGruppen,
        lieferantenGruppen: this.lieferantenGruppen,
        contactEmail: this.azureEmail,
        statusSupplier: this.selectedStatus,
      },
    });
  }
  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('number'), value: 'sp_zrnummer' },
      { text: this.$t('name'), value: 'sp_name' },
      { text: this.$t('updated_on'), value: 'sp_changedate' },
      //{ text: this.$t('changed_fields'), value: 'sp_changefields' }, // ds, 09.09.2024 -> according to the item AD-164
      { text: this.$t('changed_values'), value: 'sp_changevalue' },
      { text: this.$t('suppliers_list_completed'), value: 'supplierDoneTimeCompleted' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  get image() {
    return {};
    return {
      src: 'img_tblUpdateSupplier.png',
      header: this.$t('title'),
      width: 360,
      height: 200,
    };
  }

  onAdd() {
    this.add();
  }

  add() {
    // this.$router.push({
    //   name: ROUTES.tblUpdateSupplier,
    //   params: {
    //     tblUpdateSupplierId: CONST.emptyGuid,
    //   },
    // });
  }

  editTblUpdateSupplier(editTblUpdateSupplier: TblUpdateSupplier) {
    // this.$router.push({
    //   name: ROUTES.tblUpdateSupplier,
    //   params: { tblUpdateSupplierId: editTblUpdateSupplier.id },
    // });
  }

  deleteTblUpdateSupplier(editedTblUpdateSupplier: TblUpdateSupplier) {
    return;
    this.$confirm
      .open(`${this.$t('delete_dialog_title')}`, `${this.$t('delete_dialog_text')}`, {
        cancelText: this.$t('delete_dialog_cancel'),
        okText: this.$t('delete_dialog_ok'),
      })
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteTblUpdateSupplier(editedTblUpdateSupplier.id).then(() => {
            this.getTblUpdateSuppliers();
          });
        }
      });
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get getFilterFields() {
    return this.tblUpdateSuppliersSearchParams.orClauseFieldsIds
      .map((x) => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  private async tblUpdateSuppliersUpdateOptions(newVal: any, oldVal: any) {
    this.tblUpdateSuppliersSearchParams.dataOption = newVal;
    this.tblUpdateSuppliersSearchParams.filter = newVal.filter;
    // `oldVal` is always `undefined`
    if (newVal != oldVal && this.tblUpdateSuppliersIsLoading != undefined && !this.isInitialRequest) this.getTblUpdateSuppliers();
  }

  private async completedSupplier(tblUpdateSupplier: any) {
    if (tblUpdateSupplier.supplierUserNameCompleted.length == 0) {
      this.actionAddSupplierCompleted({
        id: tblUpdateSupplier.id,
        zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
        contactEmail: this.azureEmail,
      }).then((completedInfo: CompletedInfo) => {
        tblUpdateSupplier.supplierDoneTimeCompleted = completedInfo.сompletedDoneTime;
        tblUpdateSupplier.supplierUserNameCompleted = completedInfo.сompletedUserName;
      });
    } else {
      this.$confirm
        .open(`${this.$t('completed_dialog_title')}`, `${this.$t('completed_dialog_title_text')}`, {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        })
        .then(async (response: any) => {
          if (response) {
            this.actionCanselSupplierCompleted({
              id: tblUpdateSupplier.id,
              zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
              contactEmail: this.azureEmail,
            }).then((completedInfo: CompletedInfo) => {
              tblUpdateSupplier.supplierDoneTimeCompleted = '';
              tblUpdateSupplier.supplierUserNameCompleted = '';
            });
          }
        });
    }
  }

  private async getCompanyZrsFromBackend() {
    // const payload = {
    //   isInSuperAdminOrSupportGroup: false,
    //   contactType: contactType,
    //   IsActive: this.searchData.isActive,
    // };
    await this.actionGetCompanyWrGruppen({ searchData: { contactEmail: this.azureEmail } })
      .then((result: CompanyWrGruppenResult[]) => {
        this.companyWrGruppen = result;
      })
      .catch((err: any) => {});
  }

  changeCompanyWrGruppen() {
    this.getTblUpdateSuppliers();
  }

  changeLieferantengruppen() {
    this.getTblUpdateSuppliers();
  }

  changeSelectStatus() {
    this.getTblUpdateSuppliers();
  }

  get labelTitel() {
    if (this.$route.fullPath.includes('/suppliers')) {
      return this.$t('supplier_status');
    } else {
      return this.$t('employee_status');
    }
  }

  closeSuppliers() {
    this.$router.push({
      name: ROUTES.tasks,
    });
  }

  getFormatingText(supplierChangeValue: any) {
    let supplierText: string = '';
    if (supplierChangeValue != null && supplierChangeValue.length != 0) {
      supplierText = supplierChangeValue.replaceAll('\n', '<br>');
    }
    return supplierText;
  }

  openSupplier(zrnummer: string) {
    let url: any =
      'https://einrichtungspartnerringvme.sharepoint.com/sites/VME-Einkauf/Lists/Lieferanten/AllItems.aspx?view=7&q=' +
      zrnummer;
    window.open(url, '_blank');
  }

  activeState = [
    {
      text: this.$i18n.t(`select_all`), //'Alle',
      value: 'all',
    },
    {
      text: this.$i18n.t(`select_offen`), //'Offen',
      value: 'open',
    },
    {
      text: this.$i18n.t(`select_erledigt`), //'Erledigt',
      value: 'done',
    },
  ];
}
